import Vue from 'vue'
//import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
//axios.defaults.headers.common['Authorization'] = token
//axios.defaults.withCredentials = true;

new Vue({
  vuetify,
  router,
//  store,
  render: h => h(App)
}).$mount('#app')
