<template>
  <div class="goods">
<v-card
        class="d-flex align-start py-2"
        color="grey lighten-3"
        flat
        tile
      >
    <v-row
      justify="space-around"
      class="text-body-2"
      >
    <v-col>
      <strong>#{{ vminfo.Alias }}</strong> {{ vminfo.ShortAddress }}
    </v-col>
    <v-col>
      {{ vminfo.Location }}
    </v-col>
    <v-col>
      <v-icon small color="brown">mdi-message-minus</v-icon>Обновлено {{ getSmartDate(updated) }}
    </v-col>
  </v-row>
  </v-card>
    <v-data-table mobile-breakpoint="0"
      :headers="headers"
      :items="goods"
      items-per-page=80
      class="elevation-2"
      hide-default-footer="true"
      disable-pagination="true"
      loading="true"
      loading-text="Загрузка данных..."
      dense>
      <template v-slot:item.Channel="{ item }">
          {{ makeCurItemShelf(item.Channel, item.Good) }}
          <v-icon color="red">{{ getCurShelfPic(item.Block) }}</v-icon>
      </template>
      <template v-slot:item.GoodName="{ item }">
          {{ item.GoodName }}
      </template>
      <template v-slot:item.AmRmdr="{ item }">
        <v-chip :color="getRmdrColor(item.AmRmdr)" dark small>
          {{ item.AmRmdr }}
        </v-chip>
      </template>
      <template v-slot:item.AmWare="{ item }">
        <v-chip :color="getRmdrColor(item.AmWare)" text-color="black" outlined small>
          {{ item.AmWare }}
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>

export default {
  name: 'Goods',
  components: {
  },

  props: {
    id: { 
      type  : String,
      default: ''
    }
  },

  data: () => ({
        
        vminfo: '',
        timer: '',
        today: '',
        updated: '',

        goods: [
        ],

        headers: [
          { text: 'Полка/Товар', value: 'Channel', align: 'center' },
          { text: 'Наименование', value: 'GoodName', align: 'center' },
          { text: 'Продано', value: 'LocCnt', align: 'right' },
          { text: 'Всего', value: 'TotCnt', align: 'right' },
          { text: 'На полке', value: 'AmRmdr', align: 'center' },
          { text: 'На складе', value: 'AmWare', align: 'center' },
        ]

  }),


	methods: {
    
    getSmartDate (val)
    {
      
console.log(val + '~' + this.today);//###
      if (val == '' || val == null)
        return '';
      if (this.today == '')
        return '';
      var date = val.getFullYear()+'-'+(val.getMonth()+1)+'-'+val.getDate();
      var time = val.getHours() + ":" + val.getMinutes() + ":" + val.getSeconds();
      if (this.today.getDate() == val.getDate() && this.today.getMonth() == val.getMonth() && this.today.getFullYear() == val.getFullYear())
        return time;
      else
        return date;        
    },

    getRmdrColor (val) {
      if (val <= 0) return 'red';
      else if (val <= 3) return 'orange';
      else return 'green';
    },

    makeCurItemShelf (chn, good)
    {
      var str = chn;
      if (good != null)
        str += " / " + good + "";
    return str;
    },

    getCurShelfPic (block)
    {
      if (block == 1)
      {
        return "mdi-close-octagon";
      }
    return null;
    },

    getDataFromApi ()
    {
      this.today = new Date();
      const vm = this;
      if (this.id != '')
      {
        
        var req;

//        this.goods = '';

        req = 'https://q.emhit.com/vm/' + Number(this.id);
        this.axios.get(req, { withCredentials: false }).then(function(response) {
          vm.vminfo = response.data[0];
//          console.log(response.data[0]);
        }, function(error) {
          console.log(error.statusText);
        });

        req = 'https://q.emhit.com/it/' + Number(this.id);
        this.axios.get(req, { withCredentials: false }).then(function(response) {
          vm.goods = response.data;
//          console.log(response.data);
        }, function(error) {
          console.log(error.statusText);
        });

        this.updated = this.today;

      }
      else
      {
      vm.goods = [ {'Channel':'Ошибка загрузки данных'} ];
      }
    }

	},
  

  mounted: function() {
	this.getDataFromApi();
  this.timer = setInterval(this.getDataFromApi, 5*60000);
  },

}
</script>
