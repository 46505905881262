<template>
  <div class="home">
    <v-data-table
      :headers="headers"
      :items="desserts"
      :items-per-page="80"
      :hide-default-footer="true"
      class="elevation-1"
     :search="search">
      <template v-slot:top>
        <v-text-field
          v-model="search"
          label="Search (UPPER CASE ONLY)"
          class="mx-4"
        ></v-text-field>
      </template>
    </v-data-table>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },

  data: () => ({
        search: '',
        calories: '',
        desserts: [
{'Channel':5, 'GoodName':'name', 'LocCnt':11, 'TotCnt':12}
        ],

        headers: [
          { text: 'Полка', value: 'Channel' },
          { text: 'Товар', value: 'GoodName' },
          { text: 'Продано', value: 'LocCnt' },
          { text: 'Всего', value: 'TotCnt' },
          { text: 'На полке', value: 'AmRmdr' },
          { text: 'На складе', value: 'AmWare' },
        ]

  }),


	methods: {
		getUsers: function() {
		}
	},
	mounted: function() {
      const vm = this;
      this.axios.get('https://q.emhit.com/vm/10020').then(function(response) {
        vm.desserts = response.data;
        console.log(response.data);
      }, function(error) {
        console.log(error.statusText);
      });
	},

}
</script>
