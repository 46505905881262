<template>
  <div class="net">
<v-card
        class="d-flex align-start py-2"
        color="grey lighten-3"
        flat
        tile
      >
    <v-row
      justify="space-around"
      class="text-body-2"
      >
    <v-col>
      <strong>#Тест{{ netinfo }}</strong> {{ netinfo.ShortAddress }}
    </v-col>
    <v-col>
      {{ netinfo }}
    </v-col>
    <v-col>
      <v-icon small color="brown">mdi-message-minus</v-icon>Обновлено {{ getSmartDate(updated) }}
    </v-col>
  </v-row>
  </v-card>
    <v-data-table mobile-breakpoint="0"
      :headers="headers"
      :single-expand="true" show-expand
      :items="vmlist"
      items-per-page=40
      class="elevation-2"
      loading="true"
      loading-text="Загрузка данных..."
      dense>
      <template v-slot:item.VMMODE="{ item }">
        <v-icon :color="getVmStatusColor(item.VMMODE,item.SessDate,item.AliveTmoM)">
          {{ getVmStatusPic(item.VMMODE) }}
        </v-icon>
      </template>
      <template v-slot:item.Alias="{ item }">
        <div class="font-weight-bold">{{ item.Alias }}</div>
      </template>
      <template v-slot:item.LAINC="{ item }">
        <div class="font-weight-bold green--text">{{ item.LAINC }}</div>
      </template>
      <template v-slot:item.LEINC="{ item }">
        <div class="green--text">{{ item.LEINC }}</div>
      </template>
      <template v-slot:item.LFINC="{ item }">
        <div class="green--text">{{ item.LFINC }}</div>
      </template>
      <template v-slot:item.LCINC="{ item }">
        <div class="green--text">{{ item.LCINC }}</div>
      </template>
      <template v-slot:item.LastReceiptDate="{ item }">
        <v-chip :color="getDeltaColor(item.LastReceiptDate)" dark small>
          {{ getDeltaDate(item.LastReceiptDate) }}
        </v-chip>
      </template>
      <template v-slot:item.SessDate="{ item }">
        <v-chip :color="getDeltaColor(item.SessDate)" dark small>
          {{ getDeltaDate(item.SessDate) }}
        </v-chip>
      </template>
      <template v-slot:item.ServDate="{ item }">
        <v-chip :color="getDeltaColor(item.ServDate)" dark small>
          {{ getDeltaDate(item.ServDate) }}
        </v-chip>
      </template>
      <template v-slot:item.LoadDate="{ item }">
        <v-chip :color="getDeltaColor(item.LoadDate)" dark small>
          {{ getDeltaDate(item.LoadDate) }}
        </v-chip>
      </template>
      <template v-slot:item.CollDate="{ item }">
        <v-chip :color="getDeltaColor(item.CollDate)" dark small>
          {{ getDeltaDate(item.CollDate) }}
        </v-chip>
      </template>
      <template v-slot:item.SETV="{ item }">
        <div class="font-weight-bold blue--text">{{ item.SETV }}&#8451;</div>
      </template>
      <template v-slot:item.SETI="{ item }">
        <div class="font-weight-bold blue--text">{{ item.SETI }}&#8451;</div>
      </template>
    </v-data-table>
  </div>
</template>

<script>

export default {
  name: 'Net',
  components: {
  },

  props: {
    id: { 
      type  : String,
      default: ''
    }
  },

  data: () => ({
        
        netinfo: '',
        timer: '',
        today: '',
        updated: '',

        vmlist: [
        ],

        headers: [
          { text: 'S', value: 'VMMODE', align: 'center' },
          { text: '#', value: 'Alias', align: 'right' },
          { text: 'Имя', value: 'ShortAddress', align: 'left' },
          { text: 'Связь', value: 'SessDate', align: 'right' },
          { text: 'Чек', value: 'LastReceiptDate', align: 'right' },
          { text: 'Всех продаж', value: 'LACNT', align: 'right' },
          { text: 'Сумма', value: 'LAINC', align: 'right' },
          { text: 'б/нал1 сумма', value: 'LEINC', align: 'right' },
          { text: 'б/нал2 сумма;', value: 'LFINC', align: 'right' },
          { text: 'Нал продаж', value: 'LCCNT', align: 'right' },
          { text: 'Сумма', value: 'LCINC', align: 'right' },
          { text: 'Сервис', value: 'ServDate', align: 'right' },
          { text: 'Загруз', value: 'LoadDate', align: 'right' },
          { text: 'Инкас', value: 'CollDate', align: 'right' },
          { text: 't товаров', value: 'SETV', align: 'right' },
          { text: 't системы', value: 'SETI', align: 'right' },
          { text: '', value: 'data-table-expand' },
        ]

  }),


	methods: {
    
    getSmartDate (val)
    {
      
//console.log(val + '~' + this.today);//###
      if (val == '' || val == null)
        return '';
      if (this.today == '')
        return '';
      var date = val.getFullYear()+'-'+String((val.getMonth()+1)).padStart(2, "0")+'-'+ String(val.getDate()).padStart(2, "0");
      var time = val.getHours() + ":" + String(val.getMinutes()).padStart(2, "0") + ":" + String(val.getSeconds()).padStart(2, "0");
      if (this.today.getDate() == val.getDate() && this.today.getMonth() == val.getMonth() && this.today.getFullYear() == val.getFullYear())
        return time;
      else
        return date;        
    },

    getDeltaDate (val)
    {
//console.log(val + '~' + this.today);
      if (val == '' || val == null)
        return '?';
      if (this.today == '')
        return 'DateErr';
      val = new Date(val);
      var difs = parseInt((this.today - val) / 1000);
      if (difs < 60)
        return '<1m';
      var difm = parseInt(difs / 60);
      if (difm < 60)
        return difm + 'm';
      var difh = parseInt(difm / 60);
      difm = difm % 60;
      var difd = parseInt(difh / 24);
      difh = difh % 24;
      if (difd > 365)
        return '>1y';
      var dif = '';
      if (difd > 0)
        dif += String(difd) + 'd';
      if ((difh > 0) || (difd > 0 && difd < 10))
        dif += String(difh) + 'h';
      if (0 == difd)
        dif += String(difm) + 'm';
      return dif;
    },

    getDeltaColor (val) {
      if (val == '' || val == null)
        return 'grey';
      if (this.today == '')
        return 'grey';
      val = new Date(val);
      var difs = (this.today - val) / 1000;

      if (difs <= 60 * 60) return 'green';  // 1 hour
      else if (difs <= 24 * 60 * 60) return 'orange'; // 1 day
      else return 'red';  // more than 1 day
    },

    getVmStatusColor (status, sessdate, alivetmo)
    {
      var val = new Date(sessdate);
      var difs = (this.today - val) / (1000 * 60);

      if (0 != alivetmo && difs >= alivetmo)
        return "grey";
      else if (0 == status)
        return "red";
      else
        return "green";
    },

    getVmStatusPic (status)
    {
      if ("0" == status)
        return "mdi-cancel";
      else
        return "mdi-check-circle";
    },

    getDataFromApi ()
    {
      this.today = new Date();
      const vm = this;
      if (this.id != '')
      {
        
        var req;

        req = 'https://q.emhit.com/net/' + Number(this.id);
        this.axios.get(req, { withCredentials: false }).then(function(response) {
          vm.vmlist = response.data;
          console.log(response.data);
        }, function(error) {
          console.log(error.statusText);
        });

        this.updated = this.today;

      }
      else
      {
      vm.vmlist = [ {'Alias':'Ошибка загрузки данных'} ];
      }
    }

	},
  

  mounted: function() {
	this.getDataFromApi();
  this.timer = setInterval(this.getDataFromApi, 1*60000);
  },

}
</script>
