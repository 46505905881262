<template>
  <div class="goods">
<v-card
        class="d-flex align-start py-2"
        color="grey lighten-3"
        flat
        tile
      >
    <v-row
      justify="space-around"
      class="text-body-2"
      >
    <v-col>
      <strong>#{{ netinfo.Alias }}</strong> {{ netinfo.ShortAddress }}
    </v-col>
    <v-col>
      {{ netinfo.Location }}
    </v-col>
    <v-col>
      <v-icon small color="brown">mdi-message-minus</v-icon>Обновлено {{ getSmartDate(updated) }}
    </v-col>
  </v-row>
  </v-card>
  </div>
</template>

<script>

export default {
  name: 'Hello',
  components: {
  },

  props: {
    id: { 
      type  : String,
      default: ''
    }
  },

  data: () => ({
        
        netinfo: '',
        today: '',
        updated: '',

  }),


	methods: {
    
    getDataFromApi ()
    {
      this.today = new Date();
      const vm = this;
      if (this.id != '')
      {
        
        var req;

//        this.goods = '';

        req = 'https://q.emhit.com/hi/' + this.id;
        this.axios.get(req, { withCredentials: false }).then(function(response) {
          vm.netinfo = response.data[0];
//          console.log(response.data[0]);
        }, function(error) {
          console.log(error.statusText);
        });

        this.updated = this.today;

      }
      else
      {
//      vm.goods = [ {'Channel':'Ошибка загрузки данных'} ];
      }
    }

	},
  

  mounted: function() {
	this.getDataFromApi();
//  this.timer = setInterval(this.getDataFromApi, 5*60000);
  },

}
</script>
