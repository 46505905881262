var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"net"},[_c('v-card',{staticClass:"d-flex align-start py-2",attrs:{"color":"grey lighten-3","flat":"","tile":""}},[_c('v-row',{staticClass:"text-body-2",attrs:{"justify":"space-around"}},[_c('v-col',[_c('strong',[_vm._v("#Тест"+_vm._s(_vm.netinfo))]),_vm._v(" "+_vm._s(_vm.netinfo.ShortAddress)+" ")]),_c('v-col',[_vm._v(" "+_vm._s(_vm.netinfo)+" ")]),_c('v-col',[_c('v-icon',{attrs:{"small":"","color":"brown"}},[_vm._v("mdi-message-minus")]),_vm._v("Обновлено "+_vm._s(_vm.getSmartDate(_vm.updated))+" ")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-2",attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"single-expand":true,"show-expand":"","items":_vm.vmlist,"items-per-page":"40","loading":"true","loading-text":"Загрузка данных...","dense":""},scopedSlots:_vm._u([{key:"item.VMMODE",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.getVmStatusColor(item.VMMODE,item.SessDate,item.AliveTmoM)}},[_vm._v(" "+_vm._s(_vm.getVmStatusPic(item.VMMODE))+" ")])]}},{key:"item.Alias",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.Alias))])]}},{key:"item.LAINC",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold green--text"},[_vm._v(_vm._s(item.LAINC))])]}},{key:"item.LEINC",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"green--text"},[_vm._v(_vm._s(item.LEINC))])]}},{key:"item.LFINC",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"green--text"},[_vm._v(_vm._s(item.LFINC))])]}},{key:"item.LCINC",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"green--text"},[_vm._v(_vm._s(item.LCINC))])]}},{key:"item.LastReceiptDate",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getDeltaColor(item.LastReceiptDate),"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.getDeltaDate(item.LastReceiptDate))+" ")])]}},{key:"item.SessDate",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getDeltaColor(item.SessDate),"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.getDeltaDate(item.SessDate))+" ")])]}},{key:"item.ServDate",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getDeltaColor(item.ServDate),"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.getDeltaDate(item.ServDate))+" ")])]}},{key:"item.LoadDate",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getDeltaColor(item.LoadDate),"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.getDeltaDate(item.LoadDate))+" ")])]}},{key:"item.CollDate",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getDeltaColor(item.CollDate),"dark":"","small":""}},[_vm._v(" "+_vm._s(_vm.getDeltaDate(item.CollDate))+" ")])]}},{key:"item.SETV",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold blue--text"},[_vm._v(_vm._s(item.SETV)+"℃")])]}},{key:"item.SETI",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold blue--text"},[_vm._v(_vm._s(item.SETI)+"℃")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }